import React from "react"
import { Icon, Table, Tooltip } from "antd"
import moment from "moment-timezone"
import { navigate, useIntl } from "gatsby-plugin-intl"

import LocalizedAniLink from "./localizedAniLink"
import IngestActionsCell from "./ingestActionsCell"
import {
  formatIngestDate,
  ingestDeleteFailed,
  ingestIsActive,
  ingestIsCreating,
  ingestIsDeleting,
} from "../services/ingest"
import IngestStatus from "./ingestStatus"
import { checkPermissions } from "../services/auth/permissions/permissions"

const MetadataIngestTable = ({
  onDeleteIngestClick,
  ingests,
  emptyMessage,
}) => {
  const intl = useIntl()

  const columns = [
    {
      title: intl.formatMessage({ id: "status" }),
      dataIndex: "status",
      key: "status",
      width: 70,
      render: (text, ingest) => <IngestStatus ingest={ingest}></IngestStatus>,
      ellipsis: true,
    },
    {
      title: "",
      dataIndex: "linked_with",
      key: "linked_with",
      align: "center",
      width: 40,
      render: (text, record) => {
        const { linked_with } = record
        if (!linked_with) {
          return null
        }
        const {
          combinedIngest: { alias, version },
        } = record
        return (
          <LocalizedAniLink to={`/ingest/${linked_with}`}>
            <Tooltip
              title={
                alias && version
                  ? intl.formatMessage(
                      { id: "combined-ingest-with" },
                      { alias, version }
                    )
                  : intl.formatMessage({ id: "combined-ingest" })
              }
            >
              <Icon
                type="link"
                size="large"
                style={{ cursor: "pointer", fontSize: "15px" }}
              />
            </Tooltip>
          </LocalizedAniLink>
        )
      },
    },
    {
      title: intl.formatMessage({ id: "models-ingestion-alias" }),
      dataIndex: "alias",
      key: "alias",
      width: "15%",
      sorter: (a, b) => (a.alias < b.alias ? -1 : a.alias > b.alias ? 1 : 0),
      sortDirections: ["ascend", "descend"],
      ellipsis: true,
    },
    {
      title: intl.formatMessage({ id: "version" }),
      dataIndex: "version",
      key: "version",
      width: "8%",
      sorter: (a, b) =>
        a.version < b.version ? -1 : a.version > b.version ? 1 : 0,
      sortDirections: ["ascend", "descend"],
      ellipsis: true,
    },
    {
      title: intl.formatMessage({ id: "created-at" }),
      dataIndex: "createdAt",
      key: "createdAt",
      // width: "15%",
      render: formatIngestDate,
      sorter: (a, b) =>
        moment(a.createdAt).isBefore(moment(b.createdAt)) ? 1 : -1,
      sortDirections: ["ascend", "descend"],
      ellipsis: true,
    },
    {
      title: intl.formatMessage({ id: "last-execution" }),
      dataIndex: "lastExecution",
      key: "lastExecution",
      // width: "15%",
      render: formatIngestDate,
      sorter: (a, b) =>
        moment(a.lastExecution).isBefore(moment(b.lastExecution)) ? 1 : -1,
      sortDirections: ["ascend", "descend"],
      ellipsis: true,
    },
    {
      title: intl.formatMessage({ id: "total-stored-entries" }),
      dataIndex: "rows",
      key: "rows",
      // width: "17.5%",
      render: rows => (typeof rows === "number" ? rows.toLocaleString() : "--"),
      sorter: (a, b) => (a.rows < b.rows ? -1 : a.rows > b.rows ? 1 : 0),
      sortDirections: ["ascend", "descend"],
      ellipsis: true,
    },
    {
      title: intl.formatMessage({ id: "actions" }),
      dataIndex: "actions",
      key: "actions",
      // width: "10%",
      width: 120,
      align: "center",
      render: (text, ingest) => {
        return (
          <IngestActionsCell
            statusChangeHidden={true}
            deleteDisabled={
              ingestIsDeleting(ingest) ||
              ingestDeleteFailed(ingest) ||
              ingestIsCreating(ingest) ||
              !checkPermissions(["ingest:delete"])
            }
            onDeleteClick={() => onDeleteIngestClick(ingest)}
            active={ingestIsActive(ingest)}
          />
        )
      },
      ellipsis: true,
    },
  ]

  return (
    <Table
      rowKey="id"
      className="ingests-table"
      showHeader={true}
      size="small"
      dataSource={ingests}
      columns={columns}
      locale={{ emptyText: emptyMessage || "" }}
      rowClassName={ingest => {
        if (ingestIsDeleting(ingest)) return "ingest-status--deleting"
        if (ingestDeleteFailed(ingest)) return "ingest-status--delete-failed"
        return ""
      }}
      pagination={{ hideOnSinglePage: true }}
      onRow={ingest => ({
        onClick: () => {
          if (!ingestDeleteFailed(ingest) && !ingestIsDeleting(ingest)) {
            navigate(`/ingest/${ingest.id}`)
          }
        },
      })}
    />
  )
}

export default MetadataIngestTable
