import React from "react"
import { Button, Icon, Modal } from "antd"
import { useIntl } from "gatsby-plugin-intl"

const IngestDeleteModal = ({ visible, onCancel, onOk }) => {
  const intl = useIntl()

  return (
    <Modal visible={visible} footer={null} closable={null}>
      <div className="ingest-delete-modal-container">
        <p className="ingest-delete-modal-header-container">
          <div className="ingest-delete-modal-header-circle">
            <Icon
              type="delete"
              style={{
                color: "#d0011b",
                fontSize: "40px",
                textAlign: "center",
              }}
            />
          </div>
          <p className="ingest-delete-modal-header-content">
            {intl.formatMessage({
              id: "ingestion-delete-modal-header",
            })}
          </p>
        </p>
        <p className="ingest-delete-modal-content">
          <p>
            {intl.formatMessage({
              id: "ingestion-delete-modal-content-question",
            })}
          </p>
          <p>
            <p>
              {intl.formatMessage({
                id: "ingestion-delete-modal-content-advice",
              })}
            </p>
          </p>
        </p>
        <div className="ingest-delete-modal-buttons">
          <Button className="knolar-button back" onClick={onCancel}>
            {intl.formatMessage({ id: "cancel" })}
          </Button>
          <Button className="knolar-button" type="primary" onClick={onOk}>
            {intl.formatMessage({
              id: "menu-ingests-delete-confirm",
            })}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default IngestDeleteModal
