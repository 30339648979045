import React from "react"
import { Button, Col, Input, message, Modal, Row, Spin, Tabs } from "antd"
import moment from "moment-timezone"
import { injectIntl } from "gatsby-plugin-intl"

import LocalizedAniLink from "../../components/localizedAniLink"
import CustomBreadcrumb from "../../components/commonBreadcrumb"
import RealTimeIngestTable from "../../components/realTimeIngestTable"
import BachIngestTable from "../../components/batchIngestTable"
import MetadataIngestTable from "../../components/metadataIngestTable"
import withAuth from "../../components/withAuthHoc"
import Template from "../../layouts/base"
import authFetch from "../../services/network"
import { checkPermissions } from "../../services/auth/permissions/permissions"
import {
  addCombinedIngestField,
  getIngests,
  ingestIsActive,
  ingestIsDeleted,
  removeVersions,
} from "../../services/ingest"
import IngestDeleteModal from "../../components/ingestDeleteModal"

const { Search } = Input
const { TabPane } = Tabs

const ONE_MIN_IN_MS = 60000

class Ingests extends React.Component {
  static ingestUrl() {
    return `${process.env.GATSBY_CONF_API_URL}/ingests/`
  }

  static _isArrayWithValues(array) {
    return Array.isArray(array) && array.length > 0
  }

  constructor(props) {
    super(props)

    this.state = {
      ingests: null,
      realTimeIngests: null,
      nearRealTimeIngests: null,
      batchIngests: null,
      metadataIngests: null,
      loading: true,
      lastUpdate: null,
      searchValue: "",
      deleteModalVisible: false,
      ingestToDelete: null,
    }

    this.interval = null
    this.controller = null

    this._onSearchInputChange = this._onSearchInputChange.bind(this)
    this._getEmptyMessage = this._getEmptyMessage.bind(this)
    this._onDeleteIngestClick = this._onDeleteIngestClick.bind(this)
    this._onDeleteCancel = this._onDeleteCancel.bind(this)
    this._onDeleteConfirm = this._onDeleteConfirm.bind(this)
    this._changeIngestStatus = this._changeIngestStatus.bind(this)
    this._tryChangeIngestStatus = this._tryChangeIngestStatus.bind(this)
  }

  componentDidMount() {
    this._loadIngests()
  }

  componentWillUnmount() {
    this._clearInterval()
  }

  _onDeleteIngestClick(ingestToDelete) {
    this.setState({ deleteModalVisible: true, ingestToDelete })
  }

  _onDeleteCancel() {
    this.setState({ deleteModalVisible: false, ingestToDelete: null })
  }

  _onDeleteConfirm() {
    this._abortInFlightRequest()
    this._clearInterval()

    const { id } = this.state.ingestToDelete
    let ingestAlias = ""

    this.setState({ deleteModalVisible: false, ingestToDelete: null })

    // Make a copy of the actual state of the ingests
    const previousIngests = this.state.ingests.slice()

    // Remove, in MEMORY, the ingest to delete to give the user the sensation of immediacy
    const ingests = this.state.ingests.map(i => {
      if (i.id === id) {
        i.status = "INGEST_STATUS_DELETE_IN_PROGRESS"
        ingestAlias = i.alias
      }
      return i
    })

    // Make the deletion visible to the user
    this.setState({ ingests }, () => {
      this._filterIngests(ingests, this.state.searchValue)
    })

    authFetch(`${process.env.GATSBY_CONF_API_URL}/ingests/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(response => {
      const { status } = response

      if (status === 200 || status === 202) {
        message.info(
          <span>
            <strong>
              {ingestAlias}
              &nbsp;
              {this.props.intl.formatMessage({
                id: "page-ingestion-detail-remove-ok-text-first",
              })}
            </strong>
            <br />
            {this.props.intl.formatMessage({
              id: "page-ingestion-detail-remove-ok-text-second",
            })}
          </span>
        )
      } else {
        if (status === 400) {
          response.json().then(response => {
            if (response.message === "DELETE_ERROR_INGEST_LINKED") {
              message.error(
                this.props.intl.formatMessage({
                  id: "page-ingestions-delete-ingestion-ko-ingestion-linked",
                })
              )
            } else {
              message.error(
                `${this.props.intl.formatMessage({
                  id: "page-ingestions-delete-ingestion-ko",
                })} Status Code: ${status}`
              )
            }
          })
        } else {
          message.error(
            `${this.props.intl.formatMessage({
              id: "page-ingestions-delete-ingestion-ko",
            })} Status Code: ${status}`
          )
        }

        // Restore the copy to avoid giving the user inconsistent information
        this._spreadIngestsIntoProperties(previousIngests)
        this.setState({ ingests: previousIngests })
      }
      setTimeout(() => {
        this._loadIngests()
      }, ONE_MIN_IN_MS)
    })
  }

  _updateIngestStatus(ingestId, newStatus) {
    return this.state.ingests.map(ingest => {
      if (ingest.id === ingestId) {
        ingest.status = newStatus
      }

      return ingest
    })
  }

  _fetchLinkedWithIngest(linked_with) {
    return authFetch(`${process.env.GATSBY_CONF_API_URL}/ingests`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(ingests =>
        ingests.find(
          ({ alias, version = "" }) => `${alias}:${version}` === linked_with
        )
      )
      .then(ingest => (ingest ? ingestIsActive(ingest) : false))
  }

  _changeIngestStatus(ingest) {
    this._abortInFlightRequest()
    this._clearInterval()

    const { id, alias } = ingest
    const status = ingestIsActive(ingest)
      ? "INGEST_STATUS_DISABLED"
      : "INGEST_STATUS_ENABLED"

    // Make a copy of the actual state of the ingests
    const previousIngests = this.state.ingests.slice()

    // Make a copy of the actual state of the ingests
    const previousStatus = ingest.status

    // Change, in MEMORY, the status of the ingest to avoid depending on the network request
    const ingests = this._updateIngestStatus(id, status)

    // Make the deletion visible to the user
    this.setState({ ingests })
    this._spreadIngestsIntoProperties(ingests)

    authFetch(`${process.env.GATSBY_CONF_API_URL}/ingests/${id}/status`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: { status },
    }).then(response => {
      if (response.status !== 200) {
        // Restore the copy to avoid giving the user inconsistent information
        this.setState({ ingests: previousIngests })
        this._spreadIngestsIntoProperties(previousIngests)

        console.error(
          `[ERROR]: Changing ingest "${alias}" status.\nStatus Code: ${status}\n${JSON.stringify(
            response
          )}`
        )
        message.error(
          this.props.intl.formatMessage({
            id: "page-ingestions-change-ingestion-status-ko",
          })
        )
      } else {
        const key =
          previousStatus === "INGEST_STATUS_ENABLED"
            ? "deactivated"
            : "activated"

        message.success(
          this.props.intl.formatMessage(
            { id: `page-ingestion-detail-${key}-successfully` },
            { alias }
          )
        )
      }

      this._loadIngests()
    })
  }

  _tryChangeIngestStatus(ingest) {
    const { alias = "", linked_with } = ingest

    if (linked_with) {
      const _formatMessage = this.props.intl.formatMessage
      const MESSAGE_KEY = "message-key-to-close-later-manually"
      const TEN_SECONDS = 10

      message.info({
        content: _formatMessage(
          {
            id: "ingestion-try-to-deactivate",
          },
          {
            alias,
            linked_with: linked_with,
          }
        ),
        key: MESSAGE_KEY,
        duration: TEN_SECONDS,
      })

      this._fetchLinkedWithIngest(linked_with).then(isActive => {
        message.destroy({ key: MESSAGE_KEY })

        if (isActive) {
          Modal.warning({
            title: _formatMessage({
              id: "ingestion-could-not-deactivate-title",
            }),
            content: _formatMessage(
              { id: "ingestion-could-not-deactivate-content" },
              { ingestionId: linked_with }
            ),
          })
        } else {
          this._changeIngestStatus(ingest)
        }
      })
    } else {
      this._changeIngestStatus(ingest)
    }
  }

  _updateLastUpdateDate() {
    this.setState({ lastUpdate: moment().format("HH:mm:ss") })
  }

  _filterIngests(ingests, searchValue) {
    if (searchValue) {
      ingests = this._filterByIngestTagAliasOrVersion(ingests, searchValue)
    }
    this._spreadIngestsIntoProperties(ingests)
  }

  _filterByIngestTagAliasOrVersion(ingests, value) {
    return value
      ? ingests.filter(
          ({ alias = "", tags = [], version = "" }) =>
            alias.toLowerCase().match(value.toLowerCase()) ||
            version.toLowerCase().match(value.toLowerCase()) ||
            tags.some(t => t.toLowerCase().match(value.toLowerCase()))
        )
      : ingests
  }

  _spreadIngestsIntoProperties(ingests = []) {
    const spreadIngests = {
      REAL_TIME: [],
      NEAR_REAL_TIME: [],
      BATCH: [],
      METADATA: [],
    }
    ingests.forEach(i => {
      if (!ingestIsDeleted(i)) {
        spreadIngests[i.type].push(i)
      }
    })
    this.setState(
      {
        realTimeIngests: spreadIngests.REAL_TIME,
        nearRealTimeIngests: spreadIngests.NEAR_REAL_TIME,
        batchIngests: spreadIngests.BATCH,
        metadataIngests: spreadIngests.METADATA,
      },
      () => this.setState({ loading: false })
    )
  }

  _onSearchInputChange(ev) {
    const searchValue = (ev.target.value || "").trimStart().trimEnd()
    this.setState({ searchValue })
    this._filterIngests(this.state.ingests, searchValue)
  }

  _loadIngestsPeriodically() {
    this._clearInterval()
    this.interval = setInterval(
      () => this._loadIngestsInBackground(),
      ONE_MIN_IN_MS
    )
  }

  _loadIngestsInBackground() {
    this.controller = new AbortController()
    const signal = this.controller.signal

    return getIngests({ signal })
      .then(response => this._onLoadIngests(response))
      .catch(reason =>
        console.error(`[ERROR]: Loading ingests in background: \n${reason}`)
      )
  }

  _clearInterval() {
    if (this.interval !== null) {
      clearInterval(this.interval)
    }
  }

  _abortInFlightRequest() {
    if (this.controller !== null) {
      this.controller.abort()
    }
  }

  _onLoadIngests(response) {
    this._updateLastUpdateDate()
    const ingestsWithoutVersion = removeVersions(response)
    const ingests = addCombinedIngestField(
      ingestsWithoutVersion,
      response
    ).filter(ingest => !ingest.linked_alias)
    this.setState({ ingests }, () => {
      this._filterIngests(ingests, this.state.searchValue)
    })
  }

  _onLoadIngestsError(error) {
    const errorMessage = this.props.intl.formatMessage(
      { id: "page-ingestions-load-ingestions-ko" },
      { error: error.message }
    )

    console.error(`[ERROR] Loading ingests: \n${error.message || error}`)
    message.error(errorMessage)
  }

  _loadIngests() {
    this._clearInterval()

    return getIngests()
      .then(response => {
        if (this.props.location.state) {
          const deleteId = this.props.location.state.deletedIngestId
          if (deleteId) {
            response = response.map(i => {
              if (i.id === deleteId) {
                i.status = "INGEST_STATUS_DELETE_IN_PROGRESS"
              }
              return i
            })
          }
        }
        this._onLoadIngests(response)
        this._loadIngestsPeriodically()
      })
      .catch(reason => this._onLoadIngestsError(reason))
      .finally(() => this.setState({ loading: false }))
  }

  _getEmptyMessage(ingests) {
    return Array.isArray(ingests) &&
      ingests.length === 0 &&
      this.state.searchValue
      ? this.props.intl.formatMessage({
          id: "page-ingestions-search-no-ingestions",
        })
      : this.props.intl.formatMessage({ id: "page-ingestions-no-ingestions" })
  }

  render() {
    const _thatIntl = this.props.intl

    return (
      <Template selected={["ingest", "ingestion-index"]}>
        <Row>
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
            <CustomBreadcrumb
              style={{ flexGrow: "1" }}
              crumbs={[
                _thatIntl.formatMessage({ id: "menu-ingestions-management" }),
              ]}
            />
            {this.state.lastUpdate !== null ? (
              <div>
                {this.props.intl.formatMessage({ id: "last-update" })}:{" "}
                {this.state.lastUpdate}
              </div>
            ) : null}
          </div>
        </Row>
        <Row>
          <Col className="knolar-intro">
            {this.props.intl.formatMessage({ id: "page-ingestions-title" })}
          </Col>
        </Row>
        <div className="content">
          <Row className="ingests-header">
            <Row>
              <Search
                className="ingests-header-search knolar-input"
                placeholder={this.props.intl.formatMessage({
                  id: "page-ingestions-search-placeholder",
                })}
                onInput={this._onSearchInputChange}
                disabled={!Ingests._isArrayWithValues(this.state.ingests)}
              />
              {checkPermissions(["ingest:write"]) ? (
                <LocalizedAniLink to="/ingest/options">
                  <Button
                    type="primary"
                    className="ingests-header-create knolar-button"
                  >
                    {this.props.intl.formatMessage({
                      id: "menu-ingestions-create",
                    })}
                  </Button>
                </LocalizedAniLink>
              ) : null}
            </Row>
          </Row>
          {this.state.loading ? (
            <Row
              style={{
                height: "200px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spin size="large" />
            </Row>
          ) : (
            <Tabs className="ingest-tabs" defaultActiveKey="1">
              <TabPane tab="Real Time" key="1" forceRender={true}>
                <RealTimeIngestTable
                  ingests={this.state.realTimeIngests}
                  emptyMessage={this._getEmptyMessage(
                    this.state.realTimeIngests
                  )}
                  onDeleteIngestClick={this._onDeleteIngestClick}
                  onIngestStatusChange={this._tryChangeIngestStatus}
                  processingStatus={this.state.processingStatus}
                />
              </TabPane>
              {/* <TabPane tab="Near Real Time" key="2">
                <RealTimeIngestTable
                  ingests={this.state.nearRealTimeIngests}
                  emptyMessage={this._getEmptyMessage(
                    this.state.nearRealTimeIngests
                  )}
                  onDeleteIngestClick={this._onDeleteIngestClick}
                  onIngestStatusChange={this._tryChangeIngestStatus}
                />
              </TabPane> */}
              <TabPane tab="Batch" key="2">
                <BachIngestTable
                  ingests={this.state.batchIngests}
                  emptyMessage={this._getEmptyMessage(this.state.batchIngests)}
                  onDeleteIngestClick={this._onDeleteIngestClick}
                  onIngestStatusChange={this._tryChangeIngestStatus}
                  processingStatus={this.state.processingStatus}
                />
              </TabPane>
              <TabPane
                tab={this.props.intl.formatMessage({ id: "models-metadata" })}
                key="3"
              >
                <MetadataIngestTable
                  ingests={this.state.metadataIngests}
                  emptyMessage={this._getEmptyMessage(
                    this.state.metadataIngests
                  )}
                  onDeleteIngestClick={this._onDeleteIngestClick}
                  onIngestStatusChange={this._tryChangeIngestStatus}
                />
              </TabPane>
            </Tabs>
          )}
        </div>
        <IngestDeleteModal
          visible={this.state.deleteModalVisible}
          onOk={this._onDeleteConfirm}
          onCancel={this._onDeleteCancel}
        />
      </Template>
    )
  }
}

export default injectIntl(withAuth(Ingests))
